import type { NextPage } from 'next';
import Image from 'next/image';
import { Drawer, Box, Typography, Stack, IconButton, Tooltip, Divider } from '@mui/material';
import CloseButton from '../closeButton/closeButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { Sync, SyncRun } from '../../types/Census.interfaces';
import { SyncRunDetails } from '../../types/Common.interfaces';
import salesforce from '../../public/images/salesforce.svg';
import hubspot from '../../public/images/hubspot.svg';
import { format, parseISO } from 'date-fns';
import { useGlobalState } from '../../context/globalState';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  segmentTitle: string;
  syncDetails: SyncRunDetails[];
  syncs: Sync[];
  syncEntity: string;
  handleViewReport: (value: any) => void;
  lastSyncRun: SyncRun | null;
  lastInitialSegmentSyncRun: SyncRun | null;
}

const SyncRunDetailsDrawer: NextPage<Props> = ({
  open, setOpen, segmentTitle, syncDetails, syncs, syncEntity, handleViewReport, lastSyncRun, lastInitialSegmentSyncRun
}) => {
  const { crmDestinationType, capitalize, checkIsInitialSyncRunAfter } = useGlobalState();

  const handleRenderCellValue = (type: string, value: any, params: any) => {
    switch (type) {
      case 'number':
        return value ? <>{value.toLocaleString()}</> : 0;
      case 'date':
        return value ? <>{format(parseISO(value), 'MM/dd/yyyy')}</> : <></>;
      case 'link':
        return (
          <Box
            component='span'
            className={lastSyncRun ? 'text-highlight u-cursor-pointer' : ''}
            onClick={() => {
              if (lastSyncRun) {
                handleViewReport(params);
              }
            }}
          >
            {value ? value.toLocaleString() : 0}
          </Box>
        );
      default:
        return <></>;
    }
  };

  const handleRenderStatus = (key: string, value: any): React.ReactElement => {
    if (key === 'status') {
      if (value !== 'Removed') {
        return (
          <Box component='span' className='u-fw-400 u-text-align-right u-w-100'>
            {`${value} Records`}
          </Box>
        );
      } else {
        return (
          <Box component='span' className='u-fw-400 u-text-align-right u-w-100'>
            Flagged for Removal
          </Box>
        );
      }
    } else {
      return (
        <Box component='span' className='u-fw-700'>
          {value}
        </Box>
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'data_property',
      headerName: 'Data Properties',
      sortable: false,
      disableColumnMenu: true,
      pinnable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      headerClassName: 'table-header',
      renderCell: (params: GridRenderCellParams) => (
        <>
          {handleRenderStatus(params.row.key, params.value)}
          {params.row.description ? (
            <Tooltip title={params.row.description} placement='right-start'>
              <IconButton size='small'>
                <InfoOutlinedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      )
    },
    {
      field: 'orgValue',
      headerName: 'Organizations',
      sortable: false,
      disableColumnMenu: true,
      pinnable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      headerClassName: 'table-header',
      renderCell: (params: GridRenderCellParams) => (
        <>{handleRenderCellValue(params.row.type, params.value, params)}</>
      )
    },
    {
      field: 'perValue',
      headerName: 'People',
      sortable: false,
      disableColumnMenu: true,
      pinnable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      headerClassName: 'table-header',
      renderCell: (params: GridRenderCellParams) => (
        <>{handleRenderCellValue(params.row.type, params.value, params)}</>
      )
    },
  ];

  return (
    <Drawer
      open={open}
      anchor='right'
      onClose={() => setOpen(false)}
      elevation={4}
      PaperProps={{
        sx: { borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', maxWidth: '600px', width: '100%' }
      }}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: 'backdrop-bg'
          }
        }
      }}
    >
      <Box className='u-py-20 u-px-20 u-relative u-h-100'>
        <Box className='u-flex u-flex-space-between u-sticky drawer-title-wrapper'>
          <Typography variant='h4' className='u-pt-12 u-text-align-left'>Sync Details</Typography>
          <CloseButton handleCloseModal={() => setOpen(false)} />
        </Box>
        <Typography variant='h6' className='u-pt-12 fs-title-big u-fw-400 u-pb-24'>{segmentTitle}</Typography>
        <Box sx={{ border: '1px solid #E6E1E5' }} className='u-p-16'>
          <Box className='u-flex u-flex-align-center u-pb-16'>
            <Typography className='fs-title-regular u-fw-700 u-flex-30 u-text-align-right'>CRM</Typography>
            <Typography className='fs-title-regular u-flex-70 u-pl-24 u-flex u-flex-align-center'>
              <Image
                src={crmDestinationType === 'salesforce' ? salesforce : hubspot}
                width={30}
                height={30}
                alt={crmDestinationType ? capitalize(crmDestinationType) : ''}
              />
              <Box component='span' className='u-ml-4'>
                {crmDestinationType ? capitalize(crmDestinationType) : ''}
              </Box>
            </Typography>
          </Box>
          <Box className='u-flex u-flex-align-center u-pb-16'>
            <Typography className='fs-title-regular u-fw-700 u-flex-30 u-text-align-right'>CRM Sync Setup</Typography>
            <Typography className='fs-title-regular u-flex-70 u-pl-24'>
              {syncs.length ? syncs[0].schedule_frequency === 'never' ? 'Manual' : 'Monthly' : ''}
            </Typography>
          </Box>
          <Box className='u-flex u-flex-align-center u-pb-16'>
            <Typography className='fs-title-regular u-fw-700 u-flex-30 u-text-align-right'>Sync</Typography>
            <Typography className='fs-title-regular u-flex-70 u-pl-24'>
              {syncEntity}
            </Typography>
          </Box>
          <Divider />
          <Box className='u-pt-16 u-flex u-pb-16'>
            <Typography className='fs-title-regular u-fw-700 u-flex-30 u-text-align-right' sx={{ maxWidth: '30%' }}>
              Sync Date Range
            </Typography>
          </Box>
          <Box className='u-flex u-flex-align-center u-pb-16'>
            <Typography className='fs-title-regular u-flex-30 u-text-align-right'>Last Sync Date</Typography>
            <Typography className='fs-title-regular u-flex-70 u-pl-24'>
              {checkIsInitialSyncRunAfter(lastInitialSegmentSyncRun, lastSyncRun) && lastInitialSegmentSyncRun ?
                format(parseISO(lastInitialSegmentSyncRun.created_at), 'MM/dd/yyyy') : lastSyncRun ?
                  format(parseISO(lastSyncRun.created_at), 'MM/dd/yyyy') : ''}
            </Typography>
          </Box>
          <Box className='u-flex u-flex-align-center u-pb-16'>
            <Typography className='fs-title-regular u-flex-30 u-text-align-right'>Most Recent Sync Date</Typography>
            <Typography className='fs-title-regular u-flex-70 u-pl-24'>
              {checkIsInitialSyncRunAfter(lastInitialSegmentSyncRun, lastSyncRun) && lastInitialSegmentSyncRun ?
                format(parseISO(lastInitialSegmentSyncRun.created_at), 'MM/dd/yyyy') : lastSyncRun ?
                  format(parseISO(lastSyncRun.created_at), 'MM/dd/yyyy') : ''}
            </Typography>
          </Box>
        </Box>
        <Box className='compare-values-wrapper u-p-16 u-mt-24'>
          <Box>
            <Typography className='fs-title-regular u-fw-700 u-pb-8'>CRM Sync Details</Typography>
            {lastSyncRun ? (
              <Typography className='fs-title-regular'>
                To see more details on Total Records Changed, click into the Updated, Added, or Flagged for Removal values.
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box className='u-w-100 u-flex u-flex-direction-column markdown-wrapper u-pb-16'>
          <Box sx={{ flexGrow: 1 }}>
            <DataGridPro
              page={0}
              pageSize={50}
              columns={columns}
              rows={syncDetails}
              getRowId={(row: GridRowModel) => row.data_property}
              sx={{
                borderRadius: '0',
                backgroundColor: '#fff',
              }}
              headerHeight={48}
              rowHeight={48}
              className='export-table'
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              hideFooter
              autoHeight
              hideFooterPagination
              pagination={false}
              hideFooterSelectedRowCount
              components={{
                NoRowsOverlay: () => (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    No sync details to display!
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height='100%' alignItems='center' justifyContent='center'>
                    No sync details to display!
                  </Stack>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SyncRunDetailsDrawer;