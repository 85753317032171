import type { NextPage } from 'next';
import { useState, useRef, useEffect } from 'react';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { Box, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import classes from './header.module.scss';

interface Props {
  title: string;
  updatedInfo: boolean;
}

const Header: NextPage<Props> = ({ title = false, updatedInfo = false }) => {
  const { openHelpPortal } = useGlobalState();
  const {
    setOpenTamDrawer, setOpenPersonaDefinitionDrawer, setOpenCustomInsightsDrawer, setOpenExportHistoryDrawer, setToolbarHeight
  } = useUiState();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const headerRef = useRef<any>(null);
  const [menuItems] = useState<{ text: string; onClick: () => void }[]>(
    [
      {
        text: 'View Export History',
        onClick: () => {
          setAnchorEl(null);
          setOpenExportHistoryDrawer(true);
        }
      },
      {
        text: 'Organization Definitions',
        onClick: () => {
          setAnchorEl(null);
          setOpenTamDrawer(true);
        }
      },
      {
        text: 'Persona Definitions',
        onClick: () => {
          setAnchorEl(null);
          setOpenPersonaDefinitionDrawer(true);
        }
      },
      {
        text: 'Buyer Insight Definitions',
        onClick: () => {
          setAnchorEl(null);
          setOpenCustomInsightsDrawer(true);
        }
      }
    ]
  );

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setToolbarHeight(headerRef.current.clientHeight);
    }
  }, [headerRef, setToolbarHeight]);

  return (
    <Box
      className='u-py-8 u-px-40 u-flex u-flex-space-between u-flex-align-center'
      sx={{ borderBottom: '1px solid #787579', minHeight: '68px' }}
      ref={headerRef}
    >
      <Box className='u-flex u-flex-align-baseline u-flex-justify-center'>
        <Typography variant='h1'>
          {title}
        </Typography>
      </Box>
      <Box className='u-flex u-flex-space-between u-flex-align-center'>
        <Box
          className={classes.helpCenter}
          onClick={() => openHelpPortal()}
        >
          <SupportAgentIcon />
        </Box>
        <Box
          className={anchorEl ? `${`${classes.moreInfo} ${classes.active}`}` : `${classes.moreInfo}`}
          onClick={(event: React.MouseEvent) => setAnchorEl(event.target)}
        >
          <MoreVertIcon sx={{ color: '#2f3e4a' }} />
        </Box>
        <Menu
          onClose={() => {
            setAnchorEl(null);
          }}
          elevation={0}
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PopoverClasses={{
            paper: 'table-menu u-br-4-px'
          }}
          MenuListProps={{
            classes: {
              root: 'list-menu'
            }
          }}
          autoFocus={false}
        >
          {menuItems.map((item: { text: string; onClick: () => void }, index: number) => (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                item.onClick();
              }}
              key={index}
              className='table-menu-item fs-body-small'
            >
              {item.text}
            </MenuItem>
          ))}
          <Box className='table-menu-close-icon'>
            <IconButton
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <CloseRoundedIcon className='fs-title-regular' />
            </IconButton>
          </Box>
        </Menu>
      </Box>
    </Box>
  );
};

export default Header;