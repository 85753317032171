import type { NextPage } from 'next';
import { Modal, Box, Fade, Button, Typography } from '@mui/material';
import CloseButton from '../../components/closeButton/closeButton';
import { Segment, Report } from '../../types/Common.interfaces';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleAccept: () => void;
  segmentName: string;
  setSegmentToDelete?: (value: Segment | null) => void;
  setReportToDelete?: (value: Report | null) => void;
  type: string;
}

const DeleteSegmentModal: NextPage<Props> = ({
  open, setOpen, handleAccept, segmentName, setSegmentToDelete, type, setReportToDelete
}) => {
  const handleCloseModal = () => {
    setOpen(false);
    setSegmentToDelete && setSegmentToDelete(null);
    setReportToDelete && setReportToDelete(null);
  };

  return (
    <Modal
      open={open}
      onClose={() => handleCloseModal()}
    >
      <Fade in={open}>
        <Box className='modal' sx={{ maxWidth: '500px' }}>
          <Box className='u-relative u-p-32'>
            <CloseButton handleCloseModal={() => handleCloseModal()} />
            <Box className='u-pt-12 u-pb-20'>
              <Typography className='fs-title-regular'>
                Are you sure you want to delete this {type} &apos;{segmentName}&apos;?
              </Typography>
              <Typography className='fs-body-regular u-pt-12'>
                This action cannot be undone.
              </Typography>
            </Box>
            <Box className='u-flex u-flex-justify-end u-pt-24'>
              <Button
                variant='outlined'
                className='outlined-button u-w-100-px'
                onClick={() => handleCloseModal()}
              >
                Cancel
              </Button>
              <Button
                className='contained-button u-ml-12 u-text-transform-none'
                variant='contained'
                sx={{ minWidth: 100 }}
                onClick={handleAccept}
              >
                Yes, delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteSegmentModal;
