import type { NextPage } from 'next';
import { Modal, Box, Fade, Typography, TextField, Button, CircularProgress, InputAdornment } from '@mui/material';
import CloseButton from '../closeButton/closeButton';
import ErrorMessages from '../errorMessages/errorMessages';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  segmentName: string;
  setSegmentName: (value: string) => void;
  handleCreateSegment: () => void;
  segmentNameError: boolean;
  setSegmentNameError: (value: boolean) => void;
  savingSegment: boolean;
  savingSegmentError: boolean;
  setSavingSegmentError: (value: boolean) => void;
  cloneSegment?: boolean;
}

const SegmentNameModal: NextPage<Props> = ({
  open, setOpen, segmentName, setSegmentName, handleCreateSegment, segmentNameError, setSegmentNameError, savingSegment,
  savingSegmentError, setSavingSegmentError, cloneSegment = false,
}) => {
  const handleCloseModal = () => {
    setOpen(false);
    setSegmentNameError(false);
    setSavingSegmentError(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => handleCloseModal}
    >
      <Fade in={open}>
        <Box className='modal' sx={{ maxWidth: '600px' }}>
          <Box className='u-relative u-p-32'>
            <Typography className='fs-title-large u-fw-500 u-pb-24'>
              {!cloneSegment ? 'Name your segment' : 'Name your cloned segment'}
              <CloseButton handleCloseModal={handleCloseModal} />
            </Typography>
            <Box>
              <TextField
                color='primary'
                fullWidth
                variant='outlined'
                size='small'
                error={segmentNameError}
                value={segmentName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSegmentName(event.target.value)}
                InputProps={{
                  endAdornment: segmentNameError ? (
                    <InputAdornment className='u-ml-8' position="end">
                      <ErrorIcon className='segment-name-error' sx={{ fontSize: '20px' }} />
                    </InputAdornment>
                  ) : null,
                  classes: {
                    notchedOutline: 'segment-name-outline'
                  }
                }}
              />
            </Box>
            <Typography
              className={`segment-modal-paragraph fs-body-regular u-pt-8 ${segmentNameError ? 'segment-name-error' : ''}`}
            >
              Max 40 letters / number and no special characters @ / [] {'{}'} # $
            </Typography>
            <Box className='u-flex u-flex-justify-end u-pt-24'>
              <Button
                variant='outlined'
                className='outlined-button u-w-100-px u-mr-12 u-text-transform-none'
                onClick={() => handleCloseModal()}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className='contained-button u-w-100-px u-text-transform-none'
                onClick={() => handleCreateSegment()}
                disabled={savingSegment}
              >
                {savingSegment ? <CircularProgress sx={{ color: '#fff', marginRight: '5px' }} size={20} /> : null}
                Save
              </Button>
            </Box>
            {savingSegmentError ? (
              <Box className='u-pt-12'>
                <ErrorMessages message='Your segment failed to save, please try again.' margin={0} />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SegmentNameModal;
